

export default function CheckboxGroup({ prefix="listgroup", onChange, children }) {
  return (
    <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      {children.map((props, index) => (
        <Item key={index} name={`${prefix}-${index}`} {...props}
          onChange={(event) => onChange(props.field, event.target.checked)}
        />
      ))}
    </ul>
  );
}


function Item({ name, value, label, onChange, context, disabled }) {
  return (
    <li className="w-full border-b border-gray-200 dark:border-gray-600 hover:bg-gray-100 hover:text-blue-700 dark:hover:bg-gray-600 dark:hover:text-white">
      <div className="flex items-center pl-3">
        <input id={name} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          onChange={onChange}
          checked={value}
          disabled={disabled}
        />
        <label htmlFor={name} className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label}
        </label>
        {context && (
          <div className="flex-shrink-0 ml-2">
            {Object.entries(context || {}).map(([k, v]) => (
              <span key={k} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-300">
                {k}: {JSON.stringify(v)}
              </span>
            ))}
          </div>
        )}
      </div>
    </li>
  );
}
