import Content from 'mythulu-core/layouts/Content';


export default function NotFound() {
  return (
    <Content className="text-center">
      404 bud
    </Content>
  );
}
