import React from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import chroma from 'chroma-js';


const axisToColor = (axis) => {
  switch (axis) {
    case 'editing_left':
      return '#E53C00';
    case 'learning_curve':
      return '#00ff00';
    case 'profanity':
      return '#FF8F00';
    case 'sexual_content':
      return '#E564B7';
    case 'gore_content':
      return '#00ffff';
    default:
      return '#000000';
  }
};

const valueToWord = (value, words) => {
  if (value < 0 || value > 1) {
    throw new Error('Value must be between 0 and 1');
  }
  const increment = 1.0 / words.length;
  const index = Math.min(Math.floor(value / increment), words.length - 1);
  return words[index];
};
const words = ['None', 'Just a hint', 'Noticeable', 'Plenty', 'Rampant', 'Thematic'];


const RadarChart = ({ checks }) => {
  const knownKeysOrder = [
    'editing_left',
    'learning_curve',
    'profanity',
    'sexual_content',
    'gore_content',
  ];

  const axes = Object.keys(checks).sort((a, b) => {
    const indexA = knownKeysOrder.indexOf(a);
    const indexB = knownKeysOrder.indexOf(b);

    if (indexA === -1 && indexB === -1) {
      return a.localeCompare(b);
    }

    if (indexA === -1) {
      return 1;
    }

    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });
  const data = axes.map((axis) => ({
    id: axis,
    ...checks[axis],
  }));

  return (
    <ResponsiveRadar
      data={data}
      keys={['max', 'min']}
      blendMode={'color-dodge'}
      indexBy="id"
      maxValue={1}
      curve={'catmullRomClosed'}
      gridLevels={5}
      gridShape="circular"
      borderWidth={2}
      borderColor={'black'}
      dotSize={10}
      dotColor={(datum) => {
        const color = checks[datum.index]?.color || axisToColor(datum.index) || 'white';
        return datum.key === "min" ? chroma(color).darken(1).toString() : color;
      }}
      dotLabelYOffset={3}
      dotBorderWidth={2}
      dotBorderColor={'white'}
      colors={{ scheme: 'set2' }}
      fillOpacity={0.95}
      animate={true}
      motionConfig="wobbly"
      isInteractive={true}
      margin={{ top: 50, bottom: 80 }}
      valueFormat={value =>
        `${valueToWord(value, words)}`
      }
      gridLabel={({ id, anchor, animated }) => {
        const min = checks[id].min;
        const max = checks[id].max;

        return (
          <text
            textAnchor={anchor}
            dominantBaseline="central"
            fill={axisToColor(id)}
            transform={animated.transform.animation.to}
          >
            {checks[id].display_name || id}: {valueToWord(
              (checks[id].inverted ? 1.0 - ((max - min) / 2 + min) : (max - min) / 2 + min),
              checks[id].words || words
            )}
          </text>
        );
      }}
      theme={{
        grid: {
          line: {
            stroke: 'gray',
            strokeWidth: 2,
          },
        },
        tooltip: {
          container: {
            background: 'white',
            color: 'black',
            fontSize: '1.2em',
          },
        },
      }}
      sliceTooltip={({ index, data }) => (
        <div
          style={{
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '8px',
          }}
        >
          <h4 className="text-2xl">{checks[index].display_name || index}</h4>
          {data.map((point) => (
            <div key={point.id} color={point.color}>
              {point.id}: {valueToWord(
                (checks[index].inverted ? 1 - point.value : point.value),
                checks[index]?.words || words
              )}
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default RadarChart;
