import { useState, useEffect } from 'react';
import { doc, getDoc, collection, Timestamp } from 'firebase/firestore';
import { firestore } from 'mythulu-core/integrations/firebase';

export default function useOrgValidation(selectedOrg, plugin, userUID) {
  const [isValid, setIsValid] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!selectedOrg || !userUID) return;

    const validateOrg = async () => {
      const c_org_plugins = collection(firestore, `orgs/${selectedOrg}/plugins`)
      const pluginProfileRef = doc(c_org_plugins, plugin);
      const query = `orgs/${selectedOrg}/plugins/${plugin}`;
      try {
        setLoading(true);
        const pluginProfile = await getDoc(pluginProfileRef);
        const profile = pluginProfile.data() || {};

        console.log(profile);
        const contract = profile.contract[Object.keys(profile.contract).sort().pop()];
        const now = Timestamp.now();
        if (now >= contract.date_start && now <= contract.date_end) {
          console.info('Contract is active until', contract.date_end.toDate(), contract.seats);
          setIsValid(!!contract.seats?.[userUID]);
        } else {
          setIsValid(false);
          console.info('Contract is not active');
        }

      } catch (err) {
        console.error(query, err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    validateOrg();
  }, [selectedOrg, plugin, userUID]);

  return { isValid, loading, error };
};
