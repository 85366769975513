import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import AdminBar from "mythulu-core/components/bars/AdminBar";
import AuthContext from "mythulu-core/contexts/auth";
import ProfileContext, { ProfileProvider } from "mythulu-core/contexts/profile";
import CheckboxGroup from "mythulu-core/components/CheckboxGroup";
import ListGroup from "mythulu-core/components/ListGroup";
import TransactionsTable from "mythulu-core/components/TransactionsTable";
import { byId, names } from "mythulu-core/components/decks";


export default function UserDetailView() {
  const { uid } = useParams();

  return (
    <ProfileProvider uid={uid}>
      <UserDetail uid={uid} />
    </ProfileProvider>
  )
}


function UserDetail({ uid }) {
  const { getCards, getLayouts } = useContext(AuthContext);
  const {
    load,
    patchProfile,
    profile,
    addSupportCoins,
  } = useContext(ProfileContext);

  const [ data, setData ] = useState(null);
  const [ _coins, setCoins ] = useState(null);
  const [ booleanSettings, setBooleanSettings ] = useState([]);
  const [ textSettings, setTextSettings ] = useState([]);

  useEffect(() => {
    load(uid);
  // eslint-disable-next-line
  }, [uid]);

  useEffect(() => {
    const data = profile();
    setData(data);
    setCoins(data?.currency?.coins);

    const items = Object.entries(data?.settings || {});
    items.sort((a, b) => a[0].localeCompare(b[0]));
    setBooleanSettings(items.filter(([k, v]) => (v === true || v === false)));
    setTextSettings(items.filter(([k, v]) => !(v === true || v === false)));
  }, [uid, profile]);


  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <AdminBar
        buttons={[
          { label: 'View on Firebase', onClick: () => {
            const url_users =  "https://console.firebase.google.com/u/0/project/mythulu-app/firestore/data/~2Fusers~2F";
            window.location = `${url_users}${uid}`;
          }},
          { label: 'Add 5 coins', onClick: () => addSupportCoins("SUPPORT5", 5) },
          { label: 'Add 10 coins', onClick: () => addSupportCoins("SUPPORT10", 10) },
        ]}
      />

      <div className="flex flex-row p-2 rounded-lg bg-zinc-700">
        {data._firebase?.photoURL && <img
          src={data._firebase?.photoURL}
          alt={data._firebase?.displayName}
          style={{ width: "150px", height: "150px", borderRadius: "10%" }}
        />}
        <div className="p-2">
          <div className="text-lg font-semibold">{data._firebase?.displayName || '—'}</div>
          <div className="text-sm">{data._firebase?.providerData?.[0]?.email || '—'}</div>
          <div className="text-sm">Via {(data._firebase?.providerData || []).map(({ providerId }) => providerId).join(', ') || '—'}</div>
          <div className="text-sm">Legacy: {JSON.stringify(data.migrated) || '—'}</div>
          <div className="text-sm">UID: <pre>{data._firebase?.uid || '—'}</pre></div>
        </div>
      </div>

      <h3 className="text-lg">Coins</h3>
      <div>
        <input
          type="number"
          defaultValue={_coins}
          onChange={event => setCoins(event.target.value)}
          className={`
            bg-slate-700
          `}
        />
        <button type="button" className="btn bg-emerald-800 rounded-sm p-2 ml-2" onClick={() => patchProfile({ currency: { coins: _coins } })}>Save</button>
      </div>
      <div className="flex flex-row p-2">
        <div className="flex flex-col w-1/2 p-2">
          <h3 className="text-lg">Preferences</h3>
          <ListGroup>{textSettings.map(([k, v]) => (
            { label: k, value: v }
          ))}</ListGroup>
        </div>

        <div className="flex flex-col w-1/2 p-2">
          <h3 className="text-lg">Settings</h3>
          <CheckboxGroup>{booleanSettings.map(([k, v]) => (
            { label: k, value: v, disabled: true/* , onChange: (value) => patchProfile({ settings: { [k]: value }}) */ }
          ))}</CheckboxGroup>
        </div>

      </div>

      <h3 className="text-lg">Unlocks</h3>
      <div className="flex flex-row p-2">
        <div className="flex flex-col w-1/2 p-2 mb-3">
          <h3 className="text-lg">{Object.entries(names).filter(
            ([id, name]) => !name.includes("(A)") && (!!data.enabled?.decks?.[id]?.source || false)
          ).length} Decks</h3>
          <CheckboxGroup
            onChange={(id, value) => patchProfile({
              enabled: {
                decks: {
                  [id]: (value ? { source: "support" } : false)
                }
              }
            })}
          >{Object.entries(names).filter(
            ([_, name]) => !name.includes("(A)")
          ).map(([id, label]) => (
            { field: id, icon: byId[id], label, value: !!(data.enabled?.decks?.[id]?.source),
              context: data.enabled?.decks?.[id]?.source ? { source: data.enabled?.decks?.[id]?.source } : undefined
            }
          ))}</CheckboxGroup>
        </div>
      </div>
      <div className="flex flex-col w-full p-2 mb-3">
        {Object.keys(getLayouts()).length && (
          <>
            <h3 className="text-lg">{Object.keys(data.enabled?.layouts?.mythulu || {}).filter(({ is_released, is_paid }) => is_released && is_paid).length} Buyable Layouts</h3>
            <CheckboxGroup
              onChange={(id, value) => patchProfile({ enabled: { layouts: { mythulu: { [id]: (value ? { source: "support" } : false) } } } })}
            >{Object.entries(getLayouts())
              .filter(([_, { is_released, is_paid }]) => is_released && is_paid)
              .sort((a, b) => a[1].collection.localeCompare(b[1].collection))
              .map(([id, context]) => {
                const { title: label } = getLayouts()[id] || {};
                const value = !!(data.enabled?.layouts?.mythulu?.[id]?.source) || false;
                return {
                  field: id,
                  label,
                  value,
                  context: { category: context.category, collection: context.collection },
                };
              })}</CheckboxGroup>
          </>
        )}
      </div>

      <h3 className="text-lg">Disabled Cards</h3>
      <table className="table-auto w-full mb-3">
        <thead>
          <tr className="bg-amber-700">
            <th className="px-4 py-2">Deck</th>
            <th className="px-4 py-2">Card</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data.cards || {}).filter(([id, info]) => info?.disabled).map(([id, info]) => (
            <tr key={id}>
              <td className="border px-4 py-2">{names[getCards()?.[id]?.deck_id]}</td>
              <td className="border px-4 py-2">{getCards()?.[id]?.title}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3 className="text-lg">Transactions</h3>
      <TransactionsTable />

      <h3 className="text-lg">Legacy Transactions</h3>
      <TransactionsTable legacy />
    </>
  );
}
