import { useContext, useCallback, useMemo } from 'react';

import AuthContext from 'mythulu-core/contexts/auth';


export function useAuth() {
  const { getUser, isAdmin } = useContext(AuthContext);
  const refreshToken = useCallback(() => getUser().getIdToken(true), [getUser]);

  const expose = useMemo(() => ({
    user: getUser(),
    isAdmin: isAdmin(),
    refreshToken,
    // onAuthStateChanged,
  }), [getUser, isAdmin, refreshToken]);

  return expose;
}
