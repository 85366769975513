import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getStorage } from 'firebase/storage';
import { ref as dbRef, push, set, getDatabase } from 'firebase/database';

import { firestore } from 'mythulu-core/integrations/firebase';
import Content from 'mythulu-core/layouts/Content';
import AuthContext from 'mythulu-core/contexts/auth';
import VisualizeChecks from '../components/VisualizeChecks';
import { firebaseSubmissionConfig } from '../config';

const Submissions = () => {
  const { orgId, callId } = useParams();
  const navigate = useNavigate();
  const { getDisplayName } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const [authorName, setAuthorName] = useState('');
  const [coverLetter, setCoverLetter] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadingIssue, setUploadingIssue] = useState(null);

  const [callData, setCallData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCallData = async () => {
      setLoading(true);

      try {
        const callDocRef = doc(firestore, `orgs/${orgId}/plugins/@agent/calls/${callId}`);
        const callDocSnapshot = await getDoc(callDocRef);
        if (callDocSnapshot.exists()) {
          setCallData(callDocSnapshot.data());
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }

      setLoading(false);
    };

    fetchCallData();
  }, [orgId, callId]);

  useEffect(() => {
    if (!authorName) {
      setAuthorName(getDisplayName());
    }
  }, [authorName, getDisplayName]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    maxFiles: 1,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file || !authorName) {
      alert('Please fill in all fields and upload a file.');
      return;
    }

    setUploading(true);
    try {
      const pipeline = callData.pipelines[callData.pipelines.length - 1];
      const submission = {
        authorName,
        coverLetter,
        fileName: file.name,
        orgId,
        callId,
        pipeline,
      };

      const dbApp = initializeApp({
        ...firebaseSubmissionConfig,
        storageBucket: `${orgId}`,
        databaseURL: `https://${orgId}.firebaseio.com`,
      }, orgId);
      getAuth(dbApp).updateCurrentUser(getAuth().currentUser);
      const db = getDatabase(dbApp);
      const newSubmissionRef = push(dbRef(db, orgId));
      const storage = getStorage(dbApp);
      const storageRef = ref(storage, `${newSubmissionRef.key}`);
      await uploadBytes(storageRef, file, {
        contentType: file.type,
        customMetadata: {
          authorName,
          pipeline,
          callId,
          orgId,
          key: newSubmissionRef.key,
        },
      });
      await set(newSubmissionRef, submission);
    } catch (error) {
      console.error(error);
      setUploading(false);
      setUploadingIssue(error);
      return;
    }

    setUploadingIssue(null);
    setUploading(false);
    navigate(`/org/${orgId}/submissions/${callId}/status`);
  };


  if (loading) {
    return <Content>Aha!  It's riiiight here somewhere...</Content>;
  }
  if (uploading) {
    return <Content>Uploading submission...</Content>;
  }

  return (
    <Content>
      {uploadingIssue && (
        <div className="bg-red-500 text-white p-4 rounded">
          <h2 className="text-xl font-bold">Error uploading submission</h2>
          <p>{uploadingIssue.message}</p>
        </div>
      )}

      <h1 className="text-4xl mb-10">
        {callData.date_open ? (
          callData.display_name
        ) : (
          "This call is not currently open to submissions."
        )}
      </h1>

      {callData.date_open && (
        <>
          <div className="flex">
            <div className="w-1/3">
              <p>Open since: {callData.date_open.toDate().toLocaleDateString()}</p>
              <p>{callData.min_length.toLocaleString()} - {callData.max_length.toLocaleString()} {callData.unit_length}</p>
              <p>{callData.rate}¢/{callData.rate_for}</p>
              <p className="mt-5">{callData.description}</p>
          </div>
          {callData.date_open && (
            <div className="w-2/3">
              <div style={{ height: 350 }} className="bg-zinc-800 rounded-xl border-amber-400 border-5">
                <h3 className="text-xl p-2 bg-zinc-900">Know your story!</h3>
                <VisualizeChecks checks={callData.checks} />
              </div>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="authorName" className="font-bold text-lg mb-2">
              Author Name:
            </label>
            <input
              id="authorName"
              type="text"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              className="border border-gray-300 p-2 rounded text-black"
            />
          </div>

          <div {...getRootProps()} className="bg-gray-200 p-4 rounded flex flex-col items-center justify-center space-y-2">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="text-gray-700 font-semibold">Drop the file here...</p>
            ) : (
              file ? (
                <p className="p-2 bg-gray-200 text-gray-700 font-semibold">
                  {(file.size /1024/1024).toLocaleString().slice(0, 4)} MB • {file.type} • {file.name}
                </p>
              ) : (
                <p className="text-gray-700 font-semibold">Drag and drop a file here, or click to select a file</p>
              )
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="coverLetter" className="font-bold text-lg mb-2">
              Cover Letter:
            </label>
            <textarea
              id="coverLetter"
              value={coverLetter}
              onChange={(e) => setCoverLetter(e.target.value)}
              className="border border-gray-300 p-2 rounded h-32 text-black"
              placeholder="What excites you about this piece for our upcoming issue?"
            />
          </div>

          <button type="submit" className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
            Submit
          </button>
        </form>
      </>
    )}
    </Content>
  );
};

export default Submissions;
