import { useContext } from 'react';

import { byId as decks, names } from 'mythulu-core/components/decks';

import AuthContext from 'mythulu-core/contexts/auth';
import ProfileContext from 'mythulu-core/contexts/profile';


export default function TransactionsTable({ legacy }) {
  const { profile } = useContext(ProfileContext);

  return (
    <table className="w-full table-auto bg-slate-800">
      <thead>
        <tr className={legacy ? 'bg-sky-700' : 'bg-rose-800'}>
          <th className="text-center p-3 w-25">Product</th>
          <th className="text-center p-3 w-10">Cost</th>
          <th className="text-left p-3">Context</th>
        </tr>
      </thead>
      <tbody>
        {(profile()?.currency?.[legacy ? 'legacy_log' : 'log'] || []).map((props, index) => (
          <Transaction key={index} odd={index % 2} {...props} />
        ))}
      </tbody>
    </table>
  );
}


function Transaction({ odd, id, type, cost, timestamp, context={}, ...extra }) {
  const { getLayouts } = useContext(AuthContext);
  return (
    <tr className={odd ? 'bg-gray-700' : ''}>
      <td className="p-1 text-center">
        {(type === "decks" || extra.deck_id) && (
          decks[extra.deck_id || id]
          && <img src={decks[extra.deck_id || id]} alt={id} height={85} width={70} />
        )}
        {type === "layouts" && (
          'layout'
        )}
        {type === "coins" && (
          'coins'
        )}
        {type === "IAP" && (
          'purchase'
        )}
        {extra.promo_code && (
          extra.promo_code
        )}
      </td>
      <td className="text-center">{cost || extra.price}</td>
      <td className="p-1">
        <div>
          <strong className="text-md">{names[extra.deck_id || id] || getLayouts()[id]?.title || "?"}</strong>
        </div>
        <small>{`${new Date(timestamp)}`}</small>
        <div>
          <span
            className="inline-block px-2 py-1 mr-2 text-xs font-semibold leading-tight text-slate-100 bg-slate-900"
            ><pre>{extra.deck_id || id}</pre></span>
        </div>

        <div>
          {Object.entries(context).map(([k, v]) => (
            <span
              key={k}
              className="inline-block px-2 py-1 mr-2 text-xs font-semibold leading-tight text-gray-700 bg-gray-200 rounded-full"
            >{k}: {typeof v === 'object' ? ((v && v.seconds) || JSON.stringify(v)) : v}</span>
          ))}
          {Object.entries(extra).map(([k, v]) => (
            v ?
            (<span
              key={k}
              className="inline-block px-2 py-1 mr-2 text-xs font-semibold leading-tight text-amber-700 bg-amber-200 rounded-full"
            >{k}: {typeof v === 'object' ? ((v && v.seconds) || JSON.stringify(v)) : v}</span>)
            : null
          ))}
        </div>
      </td>
    </tr>
  );
}
