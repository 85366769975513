import { BrowserRouter, Routes, Route } from 'react-router-dom';

import GlobalFrame from 'mythulu-core/layouts/global/GlobalFrame';
import Login from 'mythulu-core/pages/Login';
import NotFound from 'mythulu-core/pages/NotFound';

import Home from './pages/Home';
import Admin from './pages/Admin';
import UserSearch from './pages/UserSearch';
import UserDetail from './pages/UserDetail';
import Submissions from './pages/Submissions';
import SubmissionStatus from './pages/SubmissionStatus';
import Org from './pages/Org';

export default function App() {
  return (
    <BrowserRouter>
      <GlobalFrame
        nav={[
          { name: 'Network', to: '/' },
        ]}
      >
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="search" element={<UserSearch />} />
            <Route path="user/:uid" element={<UserDetail />} />
          </Route>
          <Route path="/network/:orgId" element={<Org />} />
          <Route path="/network/:orgId/:callId" element={<Submissions />} />
          <Route path="/network/:orgId/:callId/status" element={<SubmissionStatus />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </GlobalFrame>
    </BrowserRouter>
  );
}
