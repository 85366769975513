import * as config from 'mythulu-core/config';

export const links = [
  { name: 'Submissions', to: '/', active: true },
  { name: 'Cards', to: 'https://app.mythulu.com' },
  { name: 'E-Magazine', to: 'https://magazine.mythulu.com/' },
  { name: 'Store', to: 'https://mythulu.com/shop/' },
  { name: 'Contact Us', to: 'https://mythulu.com/contact-us/' },
];


export const firebaseConfig = {
  apiKey: "AIzaSyBBhr2P2SPndT2xGl8CAO-BjovQ8sYy70w",
  authDomain: "mythulu-ink.firebaseapp.com",
  databaseURL: "https://mythulu-ink-default-rtdb.firebaseio.com",
  projectId: "mythulu-ink",
  storageBucket: "mythulu-ink.appspot.com",
  messagingSenderId: "589983817645",
  appId: "1:589983817645:web:a3db4be9995dd4f76c0b24",
  measurementId: "G-R3MMK160R5"
};


export const firebaseSubmissionConfig = {
  apiKey: "AIzaSyBBhr2P2SPndT2xGl8CAO-BjovQ8sYy70w",
  authDomain: "mythulu-ink.firebaseapp.com",
  projectId: "mythulu-ink",
  appId: "1:589983817645:web:a3db4be9995dd4f76c0b24",
  measurementId: "G-R3MMK160R5"
};


export const {
    MAINTENANCE_MODE,
    // MAINTENANCE_MESSAGE,
    FIREBASE_EMULATOR_DATABASE_HOST,
    FIREBASE_EMULATOR_DATABASE_PORT,
    FIREBASE_EMULATOR_FIRESTORE_URL,
    FIREBASE_EMULATOR_AUTH_URL,
} = config;
