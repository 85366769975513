import { createContext, useReducer } from 'react';

const Context = createContext();
export default Context;

export function AuthProvider(props) {
  const [ state, dispatch ] = useReducer(reducer, defaultState);
  const context = makeContext(state, dispatch);

  return (
    <Context.Provider value={context} {...props} />
  )
}

const defaultState = {
  user: null,
  profile: {

  },
  orgValidation: {},
  claims: {
    user_id: null,
    orgs: {},
    isAdmin: null,
    email: null,
    name: null,
    picture: null,
  },
  decks: {},
  cards: {},
  layouts: {},
};

function makeContext(state, dispatch) {
  const context = {
    isAdmin: () => state.claims.isAdmin,
    orgs: () => state.claims.orgs,
    getUID: () => state.claims.user_id,
    getEmail: () => state.claims.email,
    getUser: () => state.user,
    getDecks: () => state.decks,
    getCards: () => state.cards,
    getLayouts: () => state.layouts,

    preload: async () => {},

    setAuthUser: (user) => {
      if (user) {
        user.getIdTokenResult().then(({ claims }) => {
          dispatch({ type: 'SET_USER', user, claims });
        });
      } else {
        dispatch({ type: 'SET_USER', user: null, claims: { isAdmin: null, orgs: {} } });
      }
    },

    getDisplayName: () => state.user?.displayName,
    setOrgValidation: (orgId, isValid) => {
      dispatch({ type: 'SET_ORG_VALIDATION', orgId, isValid });
    },
  };
  return context;
}

function reducer(previousState, { type, ...payload }) {
  console.log({ type, payload });
  switch (type) {
    case 'SET_USER':
      return { ...previousState, user: payload.user, claims: payload.claims };
    case 'SET_ORG_VALIDATION':
      return {
        ...previousState,
        orgValidation: {
          ...previousState.orgValidation,
          [payload.orgId]: payload.isValid,
        },
      };
    default:
      throw new Error(`Unknown action ${type}`);
  }
}
