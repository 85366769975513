export default function Footer() {
  return (
    <footer className={`
      text-sm flex flex-col items-center p-4 border-t
      text-dark-500 dark:text-light-500
      bg-slate-200 border-slate-300
      dark:bg-slate-800 dark:border-slate-700
    `}>
      <div className="h-96">
        &copy; {new Date().getFullYear()}
      </div>
    </footer>
  );
}
