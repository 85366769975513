export default function ListGroup({ children }) {
  return (
    <div className="w-full text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      {children.map((props, index) => (
        <Item key={index} index={index} {...props} />
      ))}
    </div>
  );
}


function Item({ icon, label, value, index, context }) {
  return (
    <>
      <div
        className={`
          relative inline-flex items-center w-full px-2 py-2 text-sm font-medium
          border-b border-gray-200 ${index ? '' : 'rounded-t-lg'}
          hover:bg-gray-100 hover:text-blue-700
          focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700
          dark:border-gray-600
          dark:hover:bg-gray-600 dark:hover:text-white
          dark:focus:ring-gray-500 dark:focus:text-white
        `}
      >
        {icon && <img src={icon} alt={label} width="15" className="mr-2" />}

        <span>{label}</span>
        {(value !== undefined) && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-emerald-500">
            {`${value}`}
          </span>
        )}
      </div>
    </>
  );
}
