import { useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from 'mythulu-core/auth/app';
import AuthContext, { AuthProvider } from 'mythulu-core/contexts/auth';
import GlobalNavBar from 'mythulu-core/components/global/GlobalNavBar';
import NavBar from 'mythulu-core/components/global/NavBar';
import Footer from 'mythulu-core/components/global/Footer';


export default function AuthWrapperGlobalFrame(props) {
  return (
    <AuthProvider>
      <GlobalFrame {...props} />
    </AuthProvider>
  )
}


function GlobalFrame({ maintenance=false, nav, admin, children }) {
  const { getUID, setAuthUser, preload } = useContext(AuthContext);

  // eslint-disable-next-line
  useEffect(() => onAuthStateChanged(auth, setAuthUser), []);
  useEffect(() => {
    getUID() && preload()
  // eslint-disable-next-line
  }, [getUID()])

  return (
    <div className="text-dark-800 dark:text-light-100 h-screen flex flex-col">

      {!maintenance && <GlobalNavBar />}
      {!maintenance && <NavBar nav={nav} admin={admin} />}

      <div className="flex-grow bg-slate-100 dark:bg-slate-900">
        {children}
      </div>

      {!maintenance && <div className="flex-shrink">
        <Footer />
      </div>}

    </div>
  );
}
