import { Outlet } from 'react-router-dom';

import Content from 'mythulu-core/layouts/Content';


export default function Admin() {
  return (
    <Content>
      <Outlet />
    </Content>
  );
}
