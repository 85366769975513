import React from 'react';
import { useSearchParams } from 'react-router-dom';
import * as firebaseui from 'firebaseui';

import { auth } from 'mythulu-core/auth/app';
import { useAuth } from 'mythulu-core/auth/hooks';
import options from 'mythulu-core/auth/options';
import Content from 'mythulu-core/layouts/Content';

const ui = new firebaseui.auth.AuthUI(auth);


export default function Login() {
  const { user } = useAuth();
  const [ queryParams ] = useSearchParams();
  const containerRef = React.createRef();

  const next = queryParams.get('next');

  React.useEffect(() => {
    if (user) {
      window.location.href = next;
    }
  }, [user, next]);

  React.useEffect(() => {
    if (containerRef.current?.id) {
      ui.start(`#${containerRef.current.id}`, options);
    }
  }, [containerRef]);

  return (
    <Content className="text-center">
      You'll go to <code>{next || "undefined"}</code> after login.
      <div id="firebaseui-auth-container" ref={containerRef} />
    </Content>
  );
}
