import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { firestore } from 'mythulu-core/integrations/firebase';

const useOpenCalls = (orgId) => {
  const [loading, setLoading] = useState(true);
  const [openCalls, setOpenCalls] = useState([]);

  useEffect(() => {
    if (!orgId) {
      setLoading(false);
      setOpenCalls([]);
      return;
    }

    const loadOpenCalls = async () => {
      setLoading(true);
      const callsRef = collection(firestore, `orgs/${orgId}/plugins/@agent/calls`);
      const now = Timestamp.now();
      const q = query(callsRef, where('date_close', '>=', now));
      const querySnapshot = await getDocs(q);

      const calls = [];
      querySnapshot.forEach((doc) => {
        calls.push({ id: doc.id, data: doc.data() });
      });

      setOpenCalls(calls);
      setLoading(false);
    };

    loadOpenCalls();
  }, [orgId]);

  return { loading, openCalls };
};

export default useOpenCalls;
