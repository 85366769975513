import { useState, useEffect, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, collection } from 'firebase/firestore';

import { auth } from 'mythulu-core/auth/app';
import { firestore } from 'mythulu-core/integrations/firebase';

import AuthContext from 'mythulu-core/contexts/auth';


export default function useUserOrgs() {
  const { orgs } = useContext(AuthContext);

  const [userOrgs, setUserOrgs] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!user) return;

    const fetchUserOrgs = async () => {
      const query = `users/${user.uid}`;
      try {
        console.info(query, orgs());

        setLoading(true);
        const userDoc = await getDoc(doc(collection(firestore, 'users'), user.uid));
        const orgsRefs = userDoc.data().orgs || [];
        const orgsDocs = await Promise.all(orgsRefs.map(getDoc));
        setUserOrgs(orgsDocs.map((orgDoc) => ({ id: orgDoc.id, ...orgDoc.data() })));
      } catch (err) {
        console.error(query, err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserOrgs();
  }, [user, orgs]);

  return { userOrgs, loading, error };
};
