import { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Forbidden from 'mythulu-core/pages/Forbidden';
import AuthContext from 'mythulu-core/contexts/auth';
import useOrgValidation from '../hooks/useOrgValidation';
import useOpenCalls from '../hooks/useOpenCalls';

const Org = () => {
  const { getUID } = useContext(AuthContext);
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { isValid, isLoading } = useOrgValidation(orgId, '@reader', getUID());
  const { loading: callsLoading, openCalls } = useOpenCalls(orgId);

  useEffect(() => {
    if (!callsLoading && openCalls.length === 1) {
      navigate(`/network/${orgId}/${openCalls[0].id}`);
    }
  }, [callsLoading, openCalls, navigate, orgId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isValid) {
    return <Forbidden />;
  }

  if (callsLoading) {
    return <div>Loading open calls...</div>;
  }

  const handleButtonClick = (callId) => {
    navigate(`/org/${orgId}/submissions/${callId}`);
  };

  return (
    <div>
      {openCalls.map((call) => (
        <button key={call.id} onClick={() => handleButtonClick(call.id)}>
          {call.data.display_name}
        </button>
      ))}
    </div>
  );
};

export default Org;
