import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Content from 'mythulu-core/layouts/Content';
import { auth } from 'mythulu-core/auth/app';
import useUserOrgs from '../hooks/useUserOrgs';
import useOrgValidation from '../hooks/useOrgValidation';


export default function Home() {
  const { userOrgs, loading, error } = useUserOrgs();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const {
    status,
    isValid,
    loading: validationLoading,
    error: validationError,
  } = useOrgValidation(selectedOrg?.id, '@reader', auth.currentUser?.uid);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOrg && isValid) {
      navigate(`/network/${selectedOrg.id}/`);
    }
  }, [selectedOrg, isValid, navigate]);

  useEffect(() => {
    if (userOrgs?.length === 1) {
      setSelectedOrg(userOrgs[0]);
    }
  }, [userOrgs]);

  if (status) return <Content>{status}</Content>;
  if (error) return <Content>Error: {error?.message}</Content>;
  if (validationError) return <Content>Org Error: {validationError?.message}</Content>;
  if (loading === null) return <Content>User not logged in</Content>;
  if (loading) return <Content>Loading...</Content>;
  if (validationLoading) return <Content>Checking org...</Content>;
  if (userOrgs === null) return <Content>Loading orgs...</Content>;

  const handleOrgSelect = (org) => {
    setSelectedOrg(org);
  };

  return (
    <Content>
      {userOrgs?.length === 0 && (
        <div>
          <h2>No organizations found</h2>
          <p>
            You are not a member of any organizations. Please contact your
            administrator to be added to an organization.
          </p>
        </div>
      )}
      {userOrgs?.length > 1 && (
        <div>
          <h2>Select an organization</h2>
          <ul>
            {userOrgs.map((org) => (
              <li key={org.id}>
                <button onClick={() => handleOrgSelect(org)}>
                  {org.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedOrg && (
        <div>
          {isValid ? (
            <div>
              <h2>Organization: {selectedOrg.name}</h2>
              <p>Validated and ready to proceed.</p>
            </div>
          ) : (
            <div>
              <p>Organization not validated. Please ensure you have access to the selected organization.</p>
            </div>
          )}
        </div>
      )}
    </Content>
  );
}
