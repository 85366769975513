import React from 'react';

import { AdminBarPortal } from 'mythulu-core/layouts/bars/AdminBar';

export default function Buttons({ buttons=[] }) {
  const [ _buttons, setButtons ] = React.useState([]);
  React.useEffect(() => {
    setButtons(buttons.filter(Boolean));
  }, [buttons]);
  return !_buttons.length ? null : (
    <AdminBarPortal>
      <div className="flex gap-2 px-2">
        {_buttons.map((props, i) => Button({ key: i, ...props }))}
      </div>
    </AdminBarPortal>
  );
}


function Button({ values, label, className, ...props }) {
  if (values) {
    return (
      <select
        className={`
          rounded px-2 py-1
          bg-gray-300 dark:bg-gray-600
          text-gray-800 dark:text-gray-200
          ${className}
        `}
        {...props}
      >
        {values.map((value, i) => (
          <option key={i} value={value}>{value}</option>
        ))}
      </select>
    );
  } else {
    return (
      <button
        className={`
          rounded px-2 py-1
          bg-gray-300 dark:bg-gray-600
          text-gray-800 dark:text-gray-200
          ${className}
        `}
        {...props}
      >
        {label}
      </button>
    );
  }
}
