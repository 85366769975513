import Characters from 'mythulu-core/assets/decks/Characters.jpg';
import Dialogue from 'mythulu-core/assets/decks/Dialogue.jpg';
import Elements from 'mythulu-core/assets/decks/Elements.jpg';
import Habitats from 'mythulu-core/assets/decks/Habitats.jpg';
import Relationships from 'mythulu-core/assets/decks/Relationships.jpg';
import Textures from 'mythulu-core/assets/decks/Textures.jpg';
import Traits from 'mythulu-core/assets/decks/Traits.jpg';
import Love from 'mythulu-core/assets/decks/Love.png';
import Travel from 'mythulu-core/assets/decks/Travel.png';

export const byId = {
  // A
  '6e8fb39c-de20-11e8-a049-d57108dc2101': Characters,
  '458d3b1e-de1f-11e8-a870-492520a0cf02': Elements,
  '1b0bf938-de20-11e8-88f7-2bc53dd7e235': Habitats,
  'b0edf91a-de20-11e8-b7ef-153e59c80d45': Relationships,
  'ea20fba6-de20-11e8-84b4-bb84798a5478': Textures,
  'cc08822e-de20-11e8-b774-c1d3eefa2f71': Traits,

  // B
  '824b0d1a-aefa-11ec-a823-0bca92a50c1f': Characters,
  '757f1c6e-fa34-11eb-82ad-afba1820527e': Elements,
  'd6b64e3e-aef8-11ec-a484-e98c7b2c67f1': Habitats,
  '9d53464a-aefa-11ec-9652-9b86df27ccd4': Relationships,
  'd1d13b20-aefa-11ec-b426-4d3a79e456a2': Textures,
  'bb3d34c2-aefa-11ec-a46a-ef9eecd176e7': Traits,

  // Boosters
  '79a5b814-0ca9-11e9-ac83-494de7bb4808': Dialogue,
  '16fc6082-4c78-11e9-b4b9-fd62ec371374': Travel,
  'e293792c-a04f-11ed-aa3b-fd1de9c9ba1a': Love,
}

export const names = {
  '6e8fb39c-de20-11e8-a049-d57108dc2101': "Characters (A)",
  '458d3b1e-de1f-11e8-a870-492520a0cf02': "Elements (A)",
  '1b0bf938-de20-11e8-88f7-2bc53dd7e235': "Habitats (A)",
  'b0edf91a-de20-11e8-b7ef-153e59c80d45': "Relationships (A)",
  'ea20fba6-de20-11e8-84b4-bb84798a5478': "Textures (A)",
  'cc08822e-de20-11e8-b774-c1d3eefa2f71': "Traits (A)",

  '824b0d1a-aefa-11ec-a823-0bca92a50c1f': "Characters (B)",
  '757f1c6e-fa34-11eb-82ad-afba1820527e': "Elements (B)",
  'd6b64e3e-aef8-11ec-a484-e98c7b2c67f1': "Habitats (B)",
  '9d53464a-aefa-11ec-9652-9b86df27ccd4': "Relationships (B)",
  'd1d13b20-aefa-11ec-b426-4d3a79e456a2': "Textures (B)",
  'bb3d34c2-aefa-11ec-a46a-ef9eecd176e7': "Traits (B)",

  '79a5b814-0ca9-11e9-ac83-494de7bb4808': "Dialogue",
  '16fc6082-4c78-11e9-b4b9-fd62ec371374': "Travel",
  'e293792c-a04f-11ed-aa3b-fd1de9c9ba1a': "Love",
}

export const images = {
  Characters,
  Dialogue,
  Elements,
  Habitats,
  Relationships,
  Textures,
  Traits,
  Love,
  Travel,
};
