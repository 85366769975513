import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported as analyticsSupported } from 'firebase/analytics';

import {
  FIREBASE_EMULATOR_DATABASE_HOST,
  FIREBASE_EMULATOR_DATABASE_PORT,
  FIREBASE_EMULATOR_FIRESTORE_URL,
  firebaseConfig,
} from 'config';


export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app);

analyticsSupported()
  .then(enable => enable ? getAnalytics(app) : null)
  ;

if (process.env.NODE_ENV === "development" && FIREBASE_EMULATOR_FIRESTORE_URL) {
  connectFirestoreEmulator(firestore, FIREBASE_EMULATOR_FIRESTORE_URL);
}
if (process.env.NODE_ENV === "development" && FIREBASE_EMULATOR_DATABASE_HOST) {
  connectDatabaseEmulator(database, FIREBASE_EMULATOR_DATABASE_HOST, FIREBASE_EMULATOR_DATABASE_PORT);
}
